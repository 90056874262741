import React from 'react'
import ConImg from '../assets/images/banner-find-us.jpg'
import ContactInfor from './ContactInfor'


const Contacts = () => {
  
  return (
    <div name='contacts' className='w-full my-32'>
      <div className='w-full h-[700px] bg-gray-900/90 absolute'>
        <img className='w-full h-full objcet-cover mix-blend-overlay' src={ConImg} alt="/" />
      </div>

      <div className='max-w-[1240px] mx-auto relative'>
        <div className='text-center py-8 text-slate-300'>
          <h2 className='text-4xl uppercase text-white font-bold'>Find Us</h2>
          <p className='text-2xl text-zinc-200'>
            Our offices are located at Nairobi, South B center, Dai Dai road.
          </p>
        </div>

        <div className='grid md:grid-cols-1 justify-between'>
          <div className='bg-white text-slate-900 m-4 p-8 rounded-xl shadow-2xl'>
            {/* <span className='uppercase px-3 py-1 bg-indigo-200 text-indigo-900 rounded-2xl text-sm'>Standard</span>
            <div>
              <p className='text-6xl font-bold py-4 flex'>$49<span className='text-xl text-slate-500 flex flex-col justify-end'>/mo</span></p>
            </div> */}
            <p className='text-2xl py-6 text-slate-500'>
              <p className='py-2'>We have ample parking space where we receive our customers` vehicles for drive in assessments or valuation services.</p>
              <p className='py-2'>Moreover, we have other offices in Nakuru, Eldoret, Mombasa and Thika. </p>
              <p className='py-2'>We are also planning to open branches in key town in the country in order to improve efficiency in serving our estemmed clients. </p>
            </p>

            <div className='text-2xl relative justify-center'>
              {/* <button className='w-full py-4 my-4'>Contact Us</button> */}
              <ContactInfor />
            </div> 
          </div>
        </div>
    
      </div>
    </div>
  )
}

export default Contacts