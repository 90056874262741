import React from 'react'
import homeImg from '../assets/images/evaluate1.jpeg'
// import { CloudUploadIcon, DatabaseIcon, PaperAirplaneIcon, ServerIcon } from '@heroicons/react/solid';

const Home = () => {
  return (
    // bg-zinc-200
    <div  name='home' className='ma-xw-full h-screen flex flex-col justify-between'>
          <div className='w-full h-screen  bg-gradient-to-r from-violet-400 to-fuchsia-500 absolute mix-blend-overlay'></div>
            <div className='grid md:grid-cols-2 max-w-[1240px] m-auto'>
                <div className='flex flex-col justify-center md:items-start w-full px-2 py-20'>
                    <p className='text-4xl text-blue-800 font-bold'>Introduction</p>
                    <p className='text-2xl text-black font-serif m-auto'>
                        Midlane Assessors is a registered consultancy, headed by a subtly qualified motor assessor/valuer
                        who has vast qualifications and experience. We are committed to offering professional, independent,
                        market focused services through team work to bring about change and confidence to insurance industry.
                    </p>
                </div>
                <div> 
                    <img className='max-w-full rounded-full lg:rounded-full' src={homeImg} alt="/"/>
                </div>

            </div>

         



    </div>
  )
}

export default Home