import React from 'react'
import { ArrowSmRightIcon, TruckIcon } from '@heroicons/react/outline'
import { CogIcon, DocumentReportIcon, CheckIcon } from '@heroicons/react/solid'
import ServImg from '../assets/images/service.jpg'


const Services = () => {
  return (
    <div name='services' className='w-full my-32'>
        <div className='w-full h-[700px] bg-gray-900/90 absolute'>
            <img className='w-full h-full object-cover mix-blend-overlay' src={ServImg} alt="/" />
        </div>
        <div className='max-w-[1240px] mx-auto text-white relative'>
            <div className='px-4 py-18'>
                <h2 className='text-4xl pt-8 text-slate-300 uppercase text-center font-bold'>Services</h2>
                <h3 className='text-4xl font-bold py-6 text-center'>Finding the right team</h3>
                <p className='py-4 text-2xl text-slate-300'>
                    We offer various forms of services to the motor insurance sector i.e
                    <p className='text-blue-600 font-bold'>motor valuation, pre-theft valuation, pre-accident valuation, post accident assessment,
                       garage motor repair follow up, motor repairer conformity audits, re-inspections</p>
                       among other services that the insurance company may have in place pertaining the motor sector.
                </p>
            </div>

            <div className='grid grid-cols-1 lg:grid-cols-4 relative gap-x-8 gap-y-16 px-4 pt-12 sm:pt-20 text-black'>
                <div className='bg-white rounded-xl shadow-2xl'>
                    <div className='p-8'>
                        <TruckIcon className='w-16 p-4 bg-indigo-600 text-white rounded-lg mt-[-4rem]'/> 
                        <h3 className='font-bold text-2xl my-6'>Motor valuation</h3>
                        <p className='text-gray-600 text-lg'>
                            We review age, mileage cover, mechanical history and general condition among other things. 
                        </p>
                    </div>

                    <div className='bg-slate-100 pl-8 py-4'>
                        <p className='flex items-center text-indigo-600'>Contact Us <ArrowSmRightIcon className='w-5 ml-2'/></p>
                    </div>
                </div>

                <div className='bg-white rounded-xl shadow-2xl'>
                    <div className='p-8'>
                        <CheckIcon className='w-16 p-4 bg-indigo-600 text-white rounded-lg mt-[-4rem]'/>
                        <h3 className='font-bold text-2xl my-6'>Post accident assessment</h3>
                        <p className='text-gray-600 text-lg'>
                            We confirm the extent of the damage on the said vehicle and research is done on 
                            the consistency as per claim forms before the repair cost is arrived upon.
                        </p>
                    </div>

                    <div className='bg-slate-100 pl-8 py-4'>
                        <p className='flex items-center text-indigo-600'>Contact Us <ArrowSmRightIcon className='w-5 ml-2'/></p>
                    </div>
                </div>

                <div className='bg-white rounded-xl shadow-2xl'>
                    <div className='p-8'>
                        <CogIcon className='w-16 p-4 bg-indigo-600 text-white rounded-lg mt-[-4rem]'/>
                        <h3 className='font-bold text-2xl my-6'>Garage repair</h3>
                        <p className='text-gray-600 text-lg'>
                            Follow up is a service we offer on our own volition to the insurance sector on vehicles undergoing repairs. 
                            {/* This helps towards ascertaining that our client and the insured get value for their money spent and reduces
                            cost and disputes during the process. */}

                        </p>
                    </div>

                    <div className='bg-slate-100 pl-8 py-4'>
                        <p className='flex items-center text-indigo-600'>Contact Us<ArrowSmRightIcon className='w-5 ml-2'/></p>
                    </div>
                </div>

                <div className='bg-white rounded-xl shadow-2xl'>
                <div className='p-8'>
                    <DocumentReportIcon className='w-16 p-4 bg-indigo-600 text-white rounded-lg mt-[-4rem]'/>
                    <h3 className='font-bold text-2xl my-6'>Re-inspections</h3>
                    <p className='text-gray-600 text-lg'>
                        This is a vital service after repairs.We as assessors are the last link before the insured collect their vehicle after repairs
                        and quality work is ascertained here. {/* The ability to give quality repairs to our client`s insured`s is the backbone of the motor insurance sector. 
                        In regard to this we offer audits towards determining a reliable garage panel for our clients.  */}
                    </p>
                </div>

                <div className='bg-slate-100 pl-8 py-4'>
                    <p className='flex items-center text-indigo-600'>Contact Us <ArrowSmRightIcon className='w-5 ml-2'/></p>
                </div>
            </div>

            </div>

        </div>
    
    </div>
  )
}

export default Services