import React from 'react'
import { 
  FaFacebook,
  FaGithub,
  FaInstagram,
  FaTwitter,
  FaTwitch
} from 'react-icons/fa'
import { PhoneIcon, MailIcon, LocationMarkerIcon} from '@heroicons/react/outline'

const Footer = () => {
  return (
    <div className='w-full bg-slate-900 text-gray-300 py-12 px-12 '>
      <div className='max-w-[1240] mx-16 grid md:grid-cols-3 border-b-2 border-gray-600'>
      
        <div className='flex flex-col md:items-start w-full px-2 py-2 mt-12'>
          <LocationMarkerIcon className='w-14 p-4 bg-indigo-600 text-white rounded-lg mt-[-4rem]'/> 
          <h3 className='text-xl uppercase text-white font-bold py-2'>Address</h3>
          <p className='py-2'> South B Shopping Center, Dai Dai Road</p>
          <p className='py-2'> P.O.Box 37502-00100 Nairobi-Kenya</p>
        </div>

        <div className='flex flex-col md:items-start w-full px-2 py-2 mt-12'>
          <PhoneIcon className='w-14 p-4 bg-indigo-600 text-white rounded-lg mt-[-4rem]'/> 
          <h3 className='text-2xl uppercase text-white font-bold py-2'>Phone</h3>
          <p className='py-2'>+254 (0) 0798 312 266</p>
        </div>

        <div className='flex flex-col md:items-start w-full px-2 py-2 mt-12'>
          <MailIcon className='w-14 p-4 bg-indigo-600 text-white rounded-lg mt-[-4rem]'/> 
          <h3 className='text-xl uppercase text-white font-bold py-2'>Email</h3>
          <p className='py-2'>midlaneassessors@gmail.com</p>
        </div>
      
      </div>

      <div className='flex flex-col max-w-[1240px] py-2 mx-auto justify-between  sm:flex-row text-center text-gray-500'>
            <p>2022&copy; Midlane Assessors, All rights reserved</p>
            <div className='flex justify-between sm:w-[300px] pt-4 text-2xl'>
                <FaFacebook />
                <FaInstagram />
                <FaTwitter />
                <FaTwitch />
                <FaGithub />
            </div>
        </div>



    </div>
  )
}

export default Footer




          

          