import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';
import Contacts from './components/Contacts';
import Footer from './components/Footer';

function App() {
  return (
   <div>
    <Navbar/>
    <Home/>
    <About/>
    <Services/>
    <Contacts/>
    <Footer/>
   </div>
  );
}

export default App;
