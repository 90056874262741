import React from 'react'
import aboutImg from '../assets/images/car-value.jpg'

const About = () => {
  return (
    <div name='about' className='w-full my-32'>
            <div className='grid md:grid-cols-1 max-w-[1240px] mx-auto pb-12'>
                <h2 className='text-center text-4xl text-blue-800 font-bold'>About Us</h2>                
            </div>
            <div className='grid md:grid-cols-2 max-w-[1240px] m-auto'>
                <div className='text-center px-2 py-8'>
                    <p className='text-2xl py-6 text-gray-500'>
                       <h3 className='text-3xl text-black font-bold'>Mission</h3>
                        To continously improve motor consultancy services rendered to insurance and financial institutions for the well being of stakeholders.
                    </p>
                    <p className='text-2xl py-6 text-gray-500'>
                       <h3 className='text-3xl text-black font-bold'>Vision</h3>
                       Our vision is to be the leading professional automobile consultant within Eastern Africa, to pioneer the economic prosperity in the 
                       insurance industry. 
                    </p>
                </div>
                
                <div> 
                    <img className='max-w-full h-auto rounded-full' src={aboutImg} alt="/"/>
                </div>

            </div>

            <div className='grid md:grid-cols-1 max-w-[1240px] m-auto'>
                <div className='grid md:grid-cols-3 gap-1 px-2 text-center'>
                    <div className='border py-8 rounded-xl shadow-xl'>
                        <p className='text-6xl font-bold text-indigo-600'>100%</p>
                        <p className='text-gray-400 mt-2'>Completion</p>
                    </div>

                    <div className='border py-8 rounded-xl shadow-xl'>
                        <p className='text-6xl font-bold text-indigo-600'>24/7</p>
                        <p className='text-gray-400 mt-2'>Delivery</p>
                    </div>

                    <div className='border py-8 rounded-xl shadow-xl'>
                        <p className='text-6xl font-bold text-indigo-600'>100%</p>
                        <p className='text-gray-400 mt-2'>Integrity</p>
                    </div>
                
                </div> 
            </div>


    </div>
  )
}

export default About