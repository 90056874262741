import React, { useState } from 'react'
import { Link } from 'react-scroll'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import logo from '../assets/images/midlane-logo.png'

const Navbar = () => {
  const [nav, setNav] = useState(false)
  const handleClick = () => setNav(!nav)
  const handleClose = () => setNav(!nav)
  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  }

  return (
    <div className='w-screen h-[80px] z-10 bg-zinc-200 fixed drop-shadow-lg'>
    <div className='px-2 flex justify-between items-center w-full h-full'>
        <div className='flex items-center'>
            
            <img className='h-8 w-8' src={logo} alt="/"/> 
            <h1 className='text-3xl text-violet-500 font-bold mr-4 sm:text-4xl'>Midlane Assessors</h1>
            <ul className='hidden md:flex'>
                <li><Link to="home" smooth={true} duration={500}>Home</Link></li>
                <li><Link to="about" smooth={true} offset={-200} duration={500}>About</Link></li>
                <li><Link to="services" smooth={true} offset={-50} duration={500}>Services</Link></li>
                <li><Link to="contacts" smooth={true} offset={-100} duration={500}>Contacts</Link></li>
            </ul>
        </div>
        <div className='hidden md:flex pr-4'>
            <button onClick={() => openInNewTab('https://app.midlane.co.ke/')} className='border-none bg-transparent text-black mr-4'>Sign In</button>
        </div>
        <div className='md:hidden mr-4' onClick={handleClick}>
            {!nav ? <MenuIcon className='w-5' /> :<XIcon className='w-5'/>}
        </div>
    </div>

    <ul className={!nav ? 'hidden' : 'absolute bg-zinc-200 w-full px-8' }>
        <li className='border-b-2 border-zinc-300 w-full'><Link onClick={handleClose} to="home" smooth={true} duration={500}>Home</Link></li>
        <li className='border-b-2 border-zinc-300 w-full'><Link onClick={handleClose} to="about" smooth={true} offset={-200} duration={500}>About</Link></li>
        <li className='border-b-2 border-zinc-300 w-full'><Link onClick={handleClose} to="services" smooth={true} offset={-50} duration={500}>Services</Link></li>
        <li className='border-b-2 border-zinc-300 w-full'><Link onClick={handleClose} to="contacts" smooth={true} offset={-100} duration={500}>Contacts</Link></li>
        
        <div className='flex flex-col my-4'>
            <button className='bg-transparent text-indigo-600 px-8 py-3 mb-4'>Sign In</button>
        </div>
    
    </ul>
    
</div>
  )
}

export default Navbar